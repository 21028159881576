



























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoyaltyDropdown from '../../components/LoyaltyDropdown/index.vue'
import {
  Dropdown,
  EnumLoyaltiProgramChannel,
  EnumExtraMultiplierProductType,
} from '@/app/infrastructures/misc/Constants/loyaltyProgram'
import LoyaltyInput from '../../components/LoyaltyInput/index.vue'
import controller, {
  CreateExtraMultiplierPayload,
} from '@/app/ui/controllers/MembershipFormulaController'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { validationMixin } from 'vuelidate'
import { minLength, minValue, required, ValidationRule } from 'vuelidate/lib/validators'
import { Validations } from 'vuelidate-property-decorators'
import Modal from '@/app/ui/components/Modal/index.vue'
import ConfirmationIcon from '@/app/ui/assets/icon_confirmation.vue'
import locale from '../locale'
import { EventBusConstants } from '@/app/infrastructures/misc'

interface IForm {
  channel: Dropdown
  product: Dropdown
  multiplier: Array<{
    id: number | string
    extraMultiplier: number | string
  }>
}

interface fieldMultiplierInterface {
  [key: string]: string
}

interface ValidationsInterface {
  form: {
    channel: {
      required: () => ValidationRule
    }
    product: {
      required: () => ValidationRule
    }
    multiplier: {
      required: () => ValidationRule
      minLength: ValidationRule
      $each: {
        id: {
          required: () => ValidationRule
        }
        extraMultiplier: {
          required: () => ValidationRule
          minValue: ValidationRule
        }
      }
    }
  }
}

@Component({
  name: 'LoyaltyProgramExtraMultiplierCreate',
  mixins: [validationMixin],
  components: {
    LoyaltyDropdown,
    LoyaltyInput,
    Button,
    LoadingOverlay,
    Modal,
    ConfirmationIcon,
  },
})
export default class LoyaltyProgramExtraMultiplierCreate extends Vue {
  locale = locale
  channel: Dropdown[] = [
    {
      label: EnumLoyaltiProgramChannel.CUSTOMER_APPS,
      value: EnumLoyaltiProgramChannel.CUSTOMER_APPS,
    },
    {
      label: EnumLoyaltiProgramChannel.MARKETPLACE,
      value: EnumLoyaltiProgramChannel.MARKETPLACE,
    },
    {
      label: EnumLoyaltiProgramChannel.RETAIL,
      value: EnumLoyaltiProgramChannel.RETAIL,
    },
    {
      label: EnumLoyaltiProgramChannel.NEW_COD,
      value: EnumLoyaltiProgramChannel.NEW_COD
    }
  ]
  productTypeOptions: Dropdown[] = [
    {
      label: EnumExtraMultiplierProductType.SAMEDAY,
      value: EnumExtraMultiplierProductType.SAMEDAY,
    },
    {
      label: EnumExtraMultiplierProductType.BOSSPACK,
      value: EnumExtraMultiplierProductType.BOSSPACK,
    },
    {
      label: EnumExtraMultiplierProductType.REGPACK,
      value: EnumExtraMultiplierProductType.REGPACK,
    },
    {
      label: EnumExtraMultiplierProductType.JAGOPACK,
      value: EnumExtraMultiplierProductType.JAGOPACK,
    },
    {
      label: EnumExtraMultiplierProductType.BIGPACK,
      value: EnumExtraMultiplierProductType.BIGPACK,
    },
    {
      label: EnumExtraMultiplierProductType.JUMBOPACK,
      value: EnumExtraMultiplierProductType.JUMBOPACK,
    },
    {
      label: EnumExtraMultiplierProductType.INTERPACK,
      value: EnumExtraMultiplierProductType.INTERPACK,
    },
  ]
  form: IForm = {
    channel: this.channel[-1],
    product: this.productTypeOptions[-1],
    multiplier: [],
  }
  controller = controller
  fieldMultiplier: fieldMultiplierInterface = {}
  multiplier: fieldMultiplierInterface = {}
  showModalConfirmation = false
  showModalSuccess = false

  created(): void {
    this.controller.getMultiplierLevel()
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        channel: { required },
        product: {
          required,
        },
        multiplier: {
          required,
          minLength: minLength(5),
          $each: {
            id: {
              required,
            },
            extraMultiplier: {
              required,
              minValue: minValue(1),
            },
          },
        },
      },
    }
  }

  @Watch('fieldMultiplier', { immediate: true, deep: true })
  onChangedMultiplier(value: fieldMultiplierInterface): void {
    this.form.multiplier = Object.entries(value).map(item => ({
      id: Number(item[0]),
      extraMultiplier: item[1],
    }))
  }

  private getMultiplierLevelErrors(levelId: number): string | undefined {
    const validations = this.$v
    if (validations) {
      const form = validations.form
      if (form) {
        const levelRuleIndex = form.multiplier
        if (levelRuleIndex) {
          const index = this.form.multiplier.findIndex(item => {
            return item.id === levelId
          })
          const eachIndex = levelRuleIndex.$each[index]
          if (eachIndex) {
            if (!eachIndex.extraMultiplier.required) {
              return locale.label_validation_required
            }
            if (!eachIndex.extraMultiplier.minValue) {
              return locale.label_validation_min_value_1
            }
            return undefined
          }
        }
      }
    }
    return undefined
  }

  private goToBack(): void {
    this.$router.push({ name: 'LoyaltyProgramExtraMultiplierPage' })
  }

  private onModalAction(): void {
    this.showModalConfirmation = false
    this.createExtraMultiplier()
  }

  private onSubmit(): void {
    this.showModalConfirmation = true
  }

  private createExtraMultiplier(): void {
    const payload: CreateExtraMultiplierPayload = {
      channel: <EnumLoyaltiProgramChannel>this.form.channel.value,
      productType: <EnumExtraMultiplierProductType>this.form.product.value,
      extraMultiplierInfo: this.form.multiplier,
    }
    controller.createExtraMultiplier(payload)
  }

  @Watch('controller.statusCreateExtraMultiplier')
  onStatusCreateExtraMultiplierChanged(status: string): void {
    if (
      status !== '' &&
      status === EventBusConstants.CREATE_EXTRA_MULTIPLIER_SUCCESS
    ) {
      this.showModalConfirmation = false
      this.showModalSuccess = true
    }
    controller.setStatusCreateExtraMultiplier('')
  }

  beforeDestroy(): void {
    controller.setStatusCreateExtraMultiplier('')
  }
}
